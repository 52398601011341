import { Toaster } from 'react-hot-toast';


export const AppToaster = () => (
  <Toaster
    position='bottom-right'
    containerClassName='toast-container'
    toastOptions={{
      className: 'toast',
      success: { className: 'toast success' },
      error: { className: 'toast error' }
    }}
  />
);