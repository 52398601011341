import { mainApi } from './mainApi';
import { Feedback } from './models';

export const feedbackApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getFeedbacks: build.query<
      Feedback[],
      { author?: string; action?: string } | undefined
    >( {
      query: params => ( {
        method: 'get',
        url: '/feedback',
        params: params,
      } ),
    } ),
  } ),
} );

export const { useGetFeedbacksQuery } = feedbackApi;
