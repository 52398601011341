import { mainApi } from './mainApi';
import { HttpLog } from './models';

export const logsApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getHttpLogs: build.query<HttpLog[], { start: Date; end: Date }>( {
      query: params => ( {
        url: '/logs/http',
        method: 'get',
        params,
      } ),
    } ),
    getHttpLogsBounds: build.query<{ start: Date; end: Date }, void>( {
      query: () => ( {
        url: '/logs/http/bounds',
        method: 'get',
      } ),
    } ),
  } ),
} );

export const { useGetHttpLogsBoundsQuery, useGetHttpLogsQuery } = logsApi;
