import '../../assets/css/components/navigation/NavigationPanel.css';
import { ThemeToggle } from '../ui/Controls/ThemeToggle';
import { NavigationItem, NavigationItemProps } from './NavigationItem';

export interface NavigationPanelProps {
  navigationItems: NavigationItemProps[]
}

export const NavigationPanel = ( { navigationItems } : NavigationPanelProps ) => {
  
  return (
    <div className="navigation">
      <ThemeToggle />
      <nav>
        {navigationItems.map( ( item, index ) => 
          <NavigationItem key={index} to={item.to} label={item.label} icon={item.icon} /> 
        )}
      </nav>
    </div>
  );
};