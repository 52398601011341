import { RiDeleteBin5Line } from 'react-icons/ri';
import { SuperUser } from '../../../services';
import { useDeleteSuperUserMutation } from '../../../services/superUsersApi';
import styles from './SuperUsersScreen.module.scss';

export interface SuperUserActionsProps {
  superUser: SuperUser;
}

export const SuperUserActions = ( { superUser }: SuperUserActionsProps ) => {
  const [ deleteSuperUser ] = useDeleteSuperUserMutation();

  const onDeleteClick = () => {
    deleteSuperUser( superUser._id );
  };

  return (
    <div className={styles['actions']}>
      <RiDeleteBin5Line
        className={styles['action']}
        onClick={onDeleteClick}
      />
    </div>
  );
};
