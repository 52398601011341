import { DateTime } from 'luxon';
import { HttpLog } from '../../../../services';

export const groupByUrl = ( logs: HttpLog[] ) => {
  const groups: { url: string; logs: HttpLog[] }[] = [];
  for ( const log of logs ) {
    const existingGroup = groups.find( group => group.url === log.message );
    if ( existingGroup ) {
      existingGroup.logs.push( log );
    } else {
      groups.push( { url: log.message, logs: [ log ] } );
    }
  }
  return groups.sort( ( a, b ) => ( a.logs.length > b.logs.length ? -1 : 1 ) );
};

export const groupByUser = ( logs: HttpLog[] ) => {
  const groups: { user: string; logs: HttpLog[] }[] = [];
  for ( const log of logs ) {
    const uid = log.meta.user?.uid || 'admin';
    const existingGroup = groups.find( group => group.user === uid );
    if ( existingGroup ) {
      existingGroup.logs.push( log );
    } else {
      groups.push( { user: uid, logs: [ log ] } );
    }
  }
  return groups.sort( ( a, b ) => ( a.logs.length > b.logs.length ? -1 : 1 ) );
};

export const groupByTime = async (
  logs: HttpLog[],
  { shouldSort } = { shouldSort: true }
) => {
  const sorted = shouldSort
    ? [ ...logs ].sort( ( a, b ) => ( a.timestamp > b.timestamp ? 1 : -1 ) )
    : logs;
  const groups: { date: DateTime; logs: HttpLog[] }[] = [];
  const minDate = DateTime.fromJSDate( sorted[0].timestamp );
  const maxDate = DateTime.fromJSDate( sorted[sorted.length - 1].timestamp );
  const hoursDiff = Math.ceil( maxDate.diff( minDate, 'hours' ).hours );
  const { format, hoursDivision, displayFormat } = getTimeGroupParams( hoursDiff );
  const logsDateMap = createLogsDateMap( logs, format );
  for ( let i = 0; i < hoursDiff; i += hoursDivision ) {
    const date = minDate.endOf( 'hour' ).plus( { hours: i } );
    console.log( { date: date.toFormat( format ) } );
    groups.push( { date, logs: logsDateMap[date.toFormat( format )] || [] } );
  }
  console.log( { logs, groups, logsDateMap } );
  return { groups, displayFormat };
};

function createLogsDateMap( logs: HttpLog[], format = 'dd.LL.yyyy HH' ) {
  const map: Record<string, HttpLog[]> = {};

  for ( const log of logs ) {
    const date = DateTime.fromJSDate( log.timestamp ).toFormat( format );
    map[date] = ( map[date] || [] ).concat( log );
  }

  return map;
}

function getTimeGroupParams( hoursDiff: number ): {
  format: string;
  hoursDivision: number;
  displayFormat: string;
} {
  if ( hoursDiff <= 1 )
    return {
      format: 'dd.LL.yyyy HH:mm ss',
      hoursDivision: 5 / 60,
      displayFormat: 'HH:mm',
    };
  if ( hoursDiff >= 24 * 7 )
    return {
      format: 'dd.LL.yyyy',
      hoursDivision: 12,
      displayFormat: 'dd LLLL HH:00',
    };
  if ( hoursDiff < 12 )
    return {
      format: 'dd.LL.yyyy HH:mm',
      hoursDivision: 1 / 4,
      displayFormat: 'HH:mm',
    };
  return {
    format: 'dd.LL.yyyy HH',
    hoursDivision: 1,
    displayFormat: hoursDiff > 24 ? 'd LLL HH:00' : 'HH:00',
  };
}
