import { Form, Formik } from 'formik';
import { authService } from '../../services/authService';
import { useTypedSelector } from '../../state/store';
import { loginFormSchema } from '../../validation/loginFormValidation';
import { FormResponse } from '../forms/FormResponse';
import { LabeledTextInput } from '../forms/LabeledTextInput';
import { Submit } from '../forms/Submit';
import { CenterFormWrapper } from '../wrappers/CenterFormWrapper';

export interface LoginFormValues {
  login: string,
  password: string
}

const formInitialValues : LoginFormValues = {
  login: '',
  password: ''
};

export const LoginScreen = () => {

  const authError = useTypedSelector( state => state.auth.authError );
  
  return (
    <CenterFormWrapper>
      <Formik
        initialValues={formInitialValues}
        onSubmit={( values ) => {
          authService.login( values.login, values.password );
        }}
        validationSchema={loginFormSchema}
      >
        <Form>
          <h1>Log In</h1>
          <LabeledTextInput name='login' label='Login' />
          <LabeledTextInput name='password' label='Password' />
          <FormResponse text='Login or password is incorrect' hidden={!authError} />
          <Submit text='Log In' />
        </Form>
      </Formik>
    </CenterFormWrapper>
  );
};