import React from 'react';
import ReactModal from 'react-modal';
import '../../../assets/css/components/modal/modal.css';
import { ModalBody } from './ModalBody';
import { ModalTitle } from './ModalTitle';

export type ModalCloseFunction = ( e: React.MouseEvent | React.KeyboardEvent ) => void;

export interface ModalProps {
  isOpen: boolean,
  children?: React.ReactNode,
  onClose: ModalCloseFunction,
  title?: string,
  className?: string
}

export const Modal = (
  { isOpen, children, onClose, title='', className } : ModalProps
) => {

  return (
    <ReactModal
      isOpen={isOpen}
      appElement={document.getElementsByTagName( 'body' )}
      className={{
        base: [ 'modal', className ].join( ' ' ),
        afterOpen: 'modal-after-open',
        beforeClose: 'modal-before-close'
      }}
      overlayClassName={{
        base: 'modal-overlay',
        afterOpen: 'modal-overlay-after-open',
        beforeClose: 'modal-overlay-before-close'
      }}
      onRequestClose={onClose}
      closeTimeoutMS={200}
    >
      <ModalTitle title={title} onClose={onClose} />
      <ModalBody>
        {children}
      </ModalBody>
    </ReactModal>
  );
};