import styles from '../LogsScreen.module.scss';

export interface LogsMenuProps<Screens extends string> {
  onChange: ( screen: Screens ) => void;
  currentScreen: Screens;
  screens: Screens[];
}

export const LogsMenu = <Screens extends string>( {
  onChange,
  currentScreen,
  screens,
}: LogsMenuProps<Screens> ) => {
  const onClick = ( screen: Screens ) => () => onChange( screen );

  return (
    <ul className={styles['menu']}>
      {screens.map( ( screen, index ) => (
        <li
          key={index}
          data-current={currentScreen === screen}
          onClick={onClick( screen )}
        >
          {screen}
        </li>
      ) )}
    </ul>
  );
};
