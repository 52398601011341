import { useMemo } from 'react';
import { HttpLog } from '../../../../services';
import styles from '../LogsScreen.module.scss';
import { groupByUrl, groupByUser } from '../utils';
import { LogsChart } from './LogsChart';

export interface ReportChartProps {
  logs: HttpLog[];
}

const colors = [ '#cc2414', '#cc5114', '#d9852b', '#d9bf2b', '#2ba8d9' ];

export const ReportChart = ( { logs }: ReportChartProps ) => {
  const byUrl = useMemo( () => groupByUrl( logs ), [ logs ] );

  const byUser = useMemo( () => groupByUser( logs ), [ logs ] );

  const getTopItems = <Item extends Record<string, unknown> & { logs: HttpLog[] }>(
    items: Item[],
    getTitle: ( item: Item ) => string,
    colors: string[]
  ) => {
    const top = items.slice( 0, 5 );
    const others = items
      .slice( 5 )
      .reduce( ( result, group ) => group.logs.length + result, 0 );
    return top
      .map( ( item, index ) => ( {
        value: item.logs.length,
        color: colors[index],
        title: getTitle( item ),
      } ) )
      .concat( {
        value: others,
        color: '#14c40e',
        title: 'Others',
      } );
  };

  const data1 = useMemo( () => getTopItems( byUrl, item => item.url, colors ), [ byUrl ] );
  const data2 = useMemo( () => getTopItems( byUser, item => item.user, colors ), [ byUser ] );

  return (
    <div className={styles['chart-container']}>
      <div className={styles['chart']}>
        <span>By URL</span>
        <LogsChart data={data1} />
      </div>
      <div className={styles['chart']}>
        <span>By User</span>
        <LogsChart data={data2} />
      </div>
    </div>
  );
};
