import { useEffect, useState } from 'react';
import '../../../assets/css/components/ui/toggle.css';

export interface ToggleProps {
  defaultValue?: boolean,
  onToggle: ( on: boolean ) => void
}

export const Toggle = ( { onToggle, defaultValue=false } : ToggleProps ) => {

  const [ enabled, setEnabled ] = useState( defaultValue );

  const toggle = () => setEnabled( !enabled );

  useEffect( () => onToggle( enabled ), [ enabled, onToggle ] );
  
  return (
    <div className={`toggle ${enabled ? 'enabled': 'disabled'}`} onClick={toggle}></div>
  );
};