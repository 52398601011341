import { RiArrowDownFill } from 'react-icons/ri';
import { SortFunctionCreator } from '../../../../utils';
import styles from '../TableList.module.scss';
import { TableListItemProps } from './TableListItem';

export type TableListHeaderProps<
  Item extends object,
  CustomColumns extends string = never
> = Pick<TableListItemProps<Item, CustomColumns>, 'display'> & {
  headings: Partial<Record<keyof Item | CustomColumns, string>>;
  sorting?: Partial<Record<keyof Item | CustomColumns, SortFunctionCreator<Item>>>;
  sortedBy?: {
    key: keyof Item | CustomColumns | undefined;
    order: 'desc' | 'asc';
  };
  onSort?: ( key: keyof Item | CustomColumns ) => void;
};

export const TableListHeader = <
  Item extends object,
  CustomColumns extends string = never
>( {
  display,
  headings,
  sorting,
  sortedBy,
  onSort,
}: TableListHeaderProps<Item, CustomColumns> ) => {
  return (
    <thead>
      <tr>
        {display.map( ( key, index ) => (
          <th
            key={index}
            className={sortedBy?.key === key ? styles['bold'] : undefined}
            onClick={onSort ? onSort.bind( null, key ) : undefined}
            data-sorting={sortedBy?.key === key ? sortedBy?.order : undefined}
            data-sortable={
              ( sorting && Object.keys( sorting ).includes( key as string ) ) || undefined
            }
          >
            <span>
              {headings[key]}
              {sorting?.[key] && (
                <RiArrowDownFill
                  className={styles['sort-icon']}
                  fill={sortedBy?.key === key ? '#ccc' : undefined}
                />
              )}
            </span>
          </th>
        ) )}
      </tr>
    </thead>
  );
};
