import '../../assets/css/forms/FormResponse.css';
import { RiCloseCircleLine, RiInformationLine, RiCheckDoubleLine } from 'react-icons/ri';

export interface FormResponseProps {
  text: string,
  type?: 'error' | 'info' | 'success',
  hidden?: boolean
}

export const FormResponse = ( { text, type='error', hidden=false } : FormResponseProps ) => (
  <div className={`form-response ${type} ${hidden ? 'hidden' : ''}`}>
    {type === 'error' 
    ? <RiCloseCircleLine />
    : type === 'success'
    ? <RiCheckDoubleLine />
    : <RiInformationLine />}
    {text}
  </div>
  );