import { useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { PagesPerRow } from '.';
import { ReactComponent as Arrow } from '../../../assets/icons/pagination-arrow.svg';
import { Toggle } from '../Controls/Toggle';
import './Pagination.sass';

export interface PaginationProps<Item> {
  paginateItems: Item[],
  onPageChange: ( items: Item[] ) => void,
  hideShowAll?: false
}

export const Pagination = <Item,>(
  { paginateItems, onPageChange, hideShowAll } : PaginationProps<Item>
) => {

  const [ itemOffset, setItemOffset ] = useState( 0 );
  const [ pageCount, setPageCount ] = useState( 0 );
  const [ itemsPerPage, setItemsPerPage ] = useState( 5 );
  const [ showAll, setShowAll ] = useState( false );

  const onChange = ( event: {selected: number} ) => {
    const newOffset = ( event.selected * itemsPerPage ) % paginateItems.length;
    setItemOffset( newOffset );
  };

  const onShowAllChange = ( shouldShowAll: boolean ) => {
    setShowAll( shouldShowAll );
  };

  useEffect( () => doPaginate, [ itemOffset, itemsPerPage, paginateItems ] );

  useEffect( () => {
    if ( showAll ) {
      onPageChange( paginateItems );
    } else {
      doPaginate();
    }
  }, [ showAll, paginateItems ] );

  function doPaginate () {
    const endOffset = itemOffset + itemsPerPage;
    onPageChange( paginateItems.slice( itemOffset,endOffset ) );
    setPageCount( Math.ceil( paginateItems.length / itemsPerPage ) );
  }

  const className = useMemo( () => {
    const names = [ 'pagination-container' ];
    if ( showAll ) names.push( 'showing-all' );
    return names.join( ' ' );
  }, [ showAll ] );

  return (
    <div className={className}>
      <ReactPaginate 
        pageCount={pageCount}
        onPageChange={onChange}
        pageRangeDisplayed={5}
        className='pagination'
        previousLabel={<Arrow />}
        nextLabel={<Arrow />}
        breakLabel='...'
      />
      <div className='controls'>
        {!hideShowAll && (
          <div className="show-all">
            <span>Show All</span>
            <Toggle
              onToggle={onShowAllChange}
            />
          </div>
        )}
        <PagesPerRow onChange={setItemsPerPage} />
      </div>
    </div>
  );
};