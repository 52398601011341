import { mainApi } from './mainApi';
import { ServerResponse, User } from './models';

export const usersApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getUsers: build.query<User[], void>( {
      query: () => ( { url: '/users', method: 'get' } ),
      providesTags: [ 'Users' ],
    } ),
    deleteUser: build.mutation<ServerResponse, string>( {
      query: id => ( { url: `/users/${id}`, method: 'delete' } ),
      invalidatesTags: [ 'Users' ],
    } ),
    getUsersByPhone: build.query<User[], string>( {
      query: phone => ( {
        url: '/users/byPhone',
        method: 'get',
        params: { phone },
      } ),
    } ),
  } ),
} );

export const { useGetUsersQuery, useDeleteUserMutation, useLazyGetUsersByPhoneQuery } =
  usersApi;
