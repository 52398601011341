import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useGetEventsQuery } from '../../../services/eventsApi';
import { Event } from '../../../services/models';
import { getEventUrlSignature } from '../../../utils';
import { EventEditModal } from '../../modal/EditModals/EventEditModal';
import { TableListQuery } from '../../ui/TableList/TableListQuery';
import { DetailsButton } from './components/DetailsButton';

export const EventsScreen = () => {
  const [ editModalOpened, setEditModalOpened ] = useState( false );
  const [ eventToEdit, setEventToEdit ] = useState<Event | null>( null );

  const closeModal = useCallback( () => {
    setEditModalOpened( false );
  }, [ setEditModalOpened ] );

  return (
    <>
      <TableListQuery<Event, 'details' | 'url'>
        queryHook={useGetEventsQuery}
        fieldsToDisplay={[ 'title', 'start', 'location', 'details', 'url' ]}
        headings={{
          title: 'Title',
          start: 'Start date',
          location: 'Location',
          maxMembers: 'Max members',
          members: 'Members',
          _id: 'Unique Id',
          details: 'Details',
        }}
        fieldTransformations={{
          start: date => DateTime.fromJSDate( date ).toFormat( 'ff' ),
          location: location => <div style={{ maxWidth: 300 }}>{location.title}</div>,
          members: members => members.length.toString(),
        }}
        onEdit={event => {
          setEventToEdit( event );
          setEditModalOpened( true );
        }}
        customFields={{
          details: event => <DetailsButton event={event} />,
          url: event => (
            <a href={`https://crewww.io/event/${getEventUrlSignature( event )}`}>
              {event._id}
            </a>
          ),
        }}
        editable
      />
      <EventEditModal
        isOpen={editModalOpened}
        onClose={closeModal}
        event={eventToEdit}
      />
    </>
  );
};
