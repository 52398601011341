import {
  RiCalendarEventLine,
  RiFeedbackLine,
  RiFileInfoLine,
  RiUser3Line,
} from 'react-icons/ri';
import { Navigate, Route, Routes } from 'react-router-dom';
import '../assets/css/wrappers/Container.css';
import { NavigationPanel } from '../components/navigation/NavigationPanel';
import {
  EventsScreen,
  FeedbackScreen,
  LogsScreen,
  SuperUsersScreen,
  UsersScreen,
} from '../components/screens';

export const AppRouter = () => (
  <>
    <NavigationPanel
      navigationItems={[
        { to: '/users', label: 'Users', icon: RiUser3Line },
        { to: '/events', label: 'Events', icon: RiCalendarEventLine },
        { to: '/feedback', label: 'Feedback', icon: RiFeedbackLine },
        { to: '/superUsers', label: 'Super Users', icon: RiFeedbackLine },
        { to: '/logs', label: 'Logs', icon: RiFileInfoLine },
      ]}
    />
    <div className='container'>
      <Routes>
        <Route
          path='/users'
          element={<UsersScreen />}
        />
        <Route
          path='/events'
          element={<EventsScreen />}
        />
        <Route
          path='/feedback'
          element={<FeedbackScreen />}
        />
        <Route
          path='/superUsers'
          element={<SuperUsersScreen />}
        />
        <Route
          path='/logs'
          element={<LogsScreen />}
        />
        <Route
          path='/*'
          element={<Navigate to={'/users'} />}
        />
      </Routes>
    </div>
  </>
);
