import { DateTime } from 'luxon';
import { HttpLog } from '../../../../services';
import { TableList } from '../../../ui/TableListRework';

export interface LogsListProps {
  logs: HttpLog[];
}

export const LogsList = ( { logs }: LogsListProps ) => {
  return (
    <>
      <TableList
        items={logs}
        display={[ 'message', 'method', 'timestamp', 'meta' ]}
        headings={{
          message: 'URL',
          method: 'Method',
          timestamp: 'Timestamp',
          meta: 'Body',
        }}
        transformations={{
          timestamp: timestamp => DateTime.fromJSDate( timestamp ).toFormat( 'ff' ),
          meta: () => '',
        }}
      />
    </>
  );
};
