import '../../assets/css/components/ui/button.scss';

interface SubmitProps {
  text: string;
}

export const Submit = ( { text }: SubmitProps ) => (
  <button
    type='submit'
    className='button button-click-effect'
  >
    {text}
  </button>
);
