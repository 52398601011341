

export const LightTheme = () => (
  <style>{`
    html {
      --backgroundColor: #f5f5f5;
      --textColor: #323232;
      --textColorDark: #232323;
      --textColorLight: #6a6a6a;
      --shadowColor: #aaa;
      --shadowColorDark: #888;
      --overlayBackgroundColor: #3337;
    }
  `}</style>
);