import { useEffect } from 'react';
import { LoadingIndicator } from '../components/ui/Controls/LoadingIndicator';
import { authService } from '../services/authService';
import { useTypedSelector } from '../state/store';
import { AppRouter } from './AppRouter';
import { AuthRouter } from './AuthRouter';


export const RootRouter = () => {

  const isAuthorized = useTypedSelector( state => state.auth.isAuthorized );
  const validated = useTypedSelector( state => state.auth.validated );
  
  useEffect( () => {
    if ( validated && !isAuthorized ) authService.refresh();
  }, [ isAuthorized ] );
  
  if ( isAuthorized ) {
    return <AppRouter />;
  } else if ( validated ) {
    return <AuthRouter />;
  } else {
    return <LoadingIndicator absoluteCenter/>;
  }
};