import { useMemo } from 'react';
import { HttpLog } from '../../../../services';
import { TableList } from '../../../ui/TableListRework';
import { ReportChart } from './ReportChart';
import { TimeChart } from './TimeChart';

export interface LogsReportProps {
  logs: HttpLog[];
}

export const LogsReport = ( { logs }: LogsReportProps ) => {
  const groupped = useMemo( () => {
    const groups: { url: string; logs: HttpLog[] }[] = [];
    for ( const log of logs ) {
      const existingGroup = groups.find( group => group.url === log.message );
      if ( existingGroup ) {
        existingGroup.logs.push( log );
      } else {
        groups.push( { url: log.message, logs: [ log ] } );
      }
    }
    return groups.sort( ( a, b ) => ( a.logs.length > b.logs.length ? -1 : 1 ) );
  }, [ logs ] );

  return (
    <div className='report'>
      <ReportChart logs={logs} />
      <TimeChart logs={logs} />
      <TableList
        items={groupped}
        display={[ 'url', 'logs' ]}
        headings={{ url: 'URL', logs: 'Amount' }}
        transformations={{ logs: logs => logs.length.toString() }}
      />
    </div>
  );
};
