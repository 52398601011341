import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import '../../assets/css/forms/LabeledTextInput.css';

interface LabeledTextInputProps {
  name: string,
  label: string,
  type?: 'text' | 'email',
  disabled?: boolean
}

type RecordString = Record<string, string>;

export const LabeledTextInput = ( { name, label, type='text', disabled=false } : LabeledTextInputProps ) => {

  const { values, handleChange, handleBlur, touched, errors } = useFormikContext();
  const [ focused, setFocused ] = useState( false );

  const onBlur = useCallback( ( e ) => {
    setFocused( false );
    handleBlur( e );
  }, [ handleBlur, setFocused ] );

  const onFocus = () => setFocused( true );

  const value = ( values as RecordString )[name];
  const hasError = ( errors as RecordString )[name] && ( touched as RecordString )[name];
  const error = ( errors as RecordString )[name];
  let labelClassName = '';
  if ( focused || value ) labelClassName += 'focused';
  if ( hasError ) labelClassName += ' error';

  return (
    <div className="labeled-text-input">
      <label htmlFor={name} className={labelClassName}>{label}</label>
      { hasError && <p className='error'>{error}</p> }
      <input type={type} name={name} id={name} onBlur={onBlur} onFocus={onFocus}
        onChange={handleChange} value={value} 
        className={hasError ? 'error' : ''}
        disabled={disabled}
      />
    </div>
  );
};