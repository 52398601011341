import { useFormikContext } from 'formik';
import { AsyncPaginate, Response } from 'react-select-async-paginate';
import { useLazyGetUsersByPhoneQuery } from '../../../services/usersApi';
import { useTypedSelector } from '../../../state/store';
import { themeColors } from '../../../utils';

export const UsersSelect = () => {
  const [ getUsers ] = useLazyGetUsersByPhoneQuery();

  const { theme } = useTypedSelector( state => state.app );

  const { values, setFieldValue } = useFormikContext<{
    user: { value: string; label: string };
    password: string;
  }>();

  const onChange = ( value: typeof values['user'] | null ) => {
    if ( value ) {
      setFieldValue( 'user', value );
    }
  };

  const loadOptions = async (
    search: string
  ): Promise<Response<{ label: string; value: string }, never, never>> => {
    if ( !/\+\d+/.test( search ) )
      return {
        options: [],
      };
    const users = await getUsers( search ).unwrap();
    return {
      options: users.map( user => ( { label: user.name, value: user._id } ) ),
      hasMore: false,
    };
  };

  return (
    <div
      className='select-input'
      style={{ marginBottom: 24 }}
    >
      <label htmlFor='users-select'>User</label>
      <AsyncPaginate
        id='users-select'
        loadOptions={loadOptions}
        value={values['user']}
        onChange={onChange}
        debounceTimeout={300}
        theme={base => ( {
          ...base,
          colors: themeColors( theme === 'light' ),
        } )}
        components={{
          IndicatorSeparator: null,
        }}
        pageSize={50}
        placeholder='Start typing a number'
        noOptionsMessage={( { inputValue } ) =>
          inputValue.length
            ? `No users with phone like "${inputValue}"`
            : 'Start typing a number'
        }
      />
    </div>
  );
};
