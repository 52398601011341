import { useCallback, useEffect, useMemo, useState } from 'react';
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import ReactPaginate from 'react-paginate';
import styles from '../TableList.module.scss';

const itemsPerPageVariants = [ 25, 50, 100 ];

export interface TableListPaginationProps<Item> {
  items: Item[];
  onChange?: ( items: Item[] ) => void;
  defaultState?: {
    itemOffset: number;
    itemsPerPage: number;
  };
  onStateChange?: ( state: { itemOffset: number; itemsPerPage: number } ) => void;
}

export const TableListPagination = <Item,>( {
  items,
  onChange,
  defaultState,
  onStateChange,
}: TableListPaginationProps<Item> ) => {
  const [ itemOffset, setItemOffset ] = useState( defaultState?.itemOffset || 0 );
  const [ itemsPerPage, setItemsPerPage ] = useState(
    defaultState?.itemsPerPage || itemsPerPageVariants[0]
  );
  const [ pageCount, setPageCount ] = useState( 0 );

  const itemsComparisonValue = JSON.stringify( items );

  const visibleItems = useMemo( () => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount( Math.ceil( items.length / itemsPerPage ) );
    return items.slice( itemOffset, endOffset );
  }, [ items, itemOffset, itemsPerPage, itemsComparisonValue ] );

  const onPageChange = useCallback(
    ( { selected }: { selected: number } ) => {
      setItemOffset( selected * itemsPerPage );
    },
    [ itemsPerPage ]
  );

  useEffect( () => {
    if ( ( itemOffset / itemsPerPage ) % 1 !== 0 ) {
      setItemOffset( 0 );
    }
  }, [ itemsPerPage, itemOffset ] );

  useEffect( () => {
    if ( onChange ) {
      onChange( visibleItems );
    }
  }, [ visibleItems, onChange ] );

  useEffect( () => {
    if ( onStateChange ) {
      onStateChange( { itemOffset, itemsPerPage } );
    }
  }, [ onStateChange, itemOffset, itemsPerPage ] );

  const onPerPageChange = ( num: number ) => () => setItemsPerPage( num );

  return (
    <div className={styles['pagination-container']}>
      <ReactPaginate
        pageCount={pageCount}
        onPageChange={onPageChange}
        forcePage={itemOffset / itemsPerPage}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        previousLabel={<RiArrowLeftLine />}
        nextLabel={<RiArrowRightLine />}
        containerClassName={styles['pagination']}
        pageLinkClassName={styles['page-link']}
        activeClassName={styles['active']}
        previousClassName={styles['previous']}
        nextClassName={styles['next']}
        activeLinkClassName={styles['active']}
        disabledClassName={styles['disabled']}
        breakLabel={'...'}
      />
      <div className={styles['per-page']}>
        <span>Rows per page</span>
        <ul>
          {itemsPerPageVariants.map( num => (
            <li
              key={num}
              onClick={onPerPageChange( num )}
              className={num === itemsPerPage ? styles['active'] : undefined}
            >
              {num}
            </li>
          ) )}
        </ul>
      </div>
    </div>
  );
};
