import { RiCloseLine } from 'react-icons/ri';
import { IconButton } from '../../ui/Controls/IconButton';


export interface ModalTitleProps {
  title: string,
  onClose: ( e : React.MouseEvent | React.KeyboardEvent  ) => void
}

export const ModalTitle = ( { title, onClose } : ModalTitleProps ) => (
  <div className="modal-title">
    <p>{title}</p>
    <IconButton icon={RiCloseLine} onClick={onClose} />
  </div>
);