import {
  CategoryScale,
  Chart,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { HttpLog } from '../../../../services';
import { groupByTime } from '../utils';

Chart.register( LineController, LineElement, CategoryScale, LinearScale, PointElement );

export interface TimeChartProps {
  logs: HttpLog[];
}

export const TimeChart = ( { logs }: TimeChartProps ) => {
  const [ data, setData ] = useState<number[]>( [] );
  const [ labels, setLabels ] = useState<string[]>( [] );

  useEffect( () => {
    groupByTime( logs ).then( ( { groups, displayFormat } ) => {
      setData( groups.map( g => g.logs.length ) );
      setLabels( groups.map( g => g.date.toFormat( displayFormat ) ) );
    } );
  }, [ logs ] );

  console.log( { data, labels } );

  return (
    <Line
      data={{
        labels,
        datasets: [
          {
            data,
            label: '',
            borderColor: '#f39773',
            backgroundColor: '#f39773',
            cubicInterpolationMode: 'monotone',
            pointRadius: 0,
          },
        ],
      }}
      options={{
        responsive: true,
      }}
      height={50}
    />
  );
};
