import { RiEditCircleLine } from 'react-icons/ri';
import { IconButton } from '../Controls/IconButton';
import { TableListItemProps } from './TableListItem';

export type TableListItemControlsProp<Item extends object> =
  Pick<TableListItemProps<Item>, 'item'> 
  & Required<Pick<TableListItemProps<Item>, 'onEdit'>>
  & { selectionMode?: boolean }


export const TableListItemControls = <Item extends object,>( 
  { item, onEdit, selectionMode=false } : TableListItemControlsProp<Item> 
) => (
  <td className="controls-container">
    <div className="controls">
      {
        selectionMode
        ? null
        : (
          <IconButton icon={RiEditCircleLine} onClick={() => onEdit( item )} />
        )
      }
    </div>
  </td>
);