import { useState } from 'react';
import { Event } from '../../../../services';
import { Modal } from '../../../modal';
import { Button, LabeledFieldInfo } from '../../../ui';

export interface DetailsButtonProps {
  event: Event;
}

export const DetailsButton = ( { event }: DetailsButtonProps ) => {
  const [ isOpen, setOpen ] = useState( false );

  const toggleOpen = ( value: boolean ) => () => setOpen( value );

  const info: { label: string; content: string }[] = [
    { label: 'Event type', content: event.byoc ? 'BYOC' : 'Plain' },
    { label: 'Current members count', content: event.members.length.toString() },
    { label: 'Maximum members', content: event.maxMembers.toString() },
    { label: "Creator's phone", content: event.author?.phone || '-' },
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        text='Details'
        small
        onClick={toggleOpen( true )}
      />
      <Modal
        isOpen={isOpen}
        onClose={toggleOpen( false )}
        title='User Details'
      >
        {info.map( ( { label, content }, index ) => (
          <LabeledFieldInfo
            label={label}
            key={index}
          >
            {content}
          </LabeledFieldInfo>
        ) )}
      </Modal>
    </div>
  );
};
