import { ReactNode } from 'react';
import '../../assets/css/wrappers/CenterFormWrapper.css';
import '../../assets/css/components/ui/box.css';

interface CenterFormWrapperProps {
  children: ReactNode
}

export const CenterFormWrapper = ( { children } : CenterFormWrapperProps ) => {

  return (
    <div className="center-wrap">
      <div className="box">
        {children}
      </div>
    </div>
  );
};