import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const localStorageTokenPath = 'jwt';
export const localStorageRefreshTokenPath = 'refreshToken';

interface AuthState {
  accessToken: string,
  isAuthorized: boolean,
  authError: string,
  refreshToken: string,
  validated: boolean
}

const initialState : AuthState = {
  accessToken: localStorage.getItem( localStorageTokenPath ) || '',
  isAuthorized: false,
  authError: '',
  refreshToken: localStorage.getItem( localStorageRefreshTokenPath ) || '',
  validated: false
};

export const authSlice = createSlice( {
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: ( state, action: PayloadAction<string> ) => {
      state.accessToken = action.payload;
      localStorage.setItem( localStorageTokenPath, action.payload );
      if ( action.payload ) state.authError = '';
      state.isAuthorized = !!action.payload;
    },
    setAuthError: ( state, action: PayloadAction<string> ) => {
      state.isAuthorized = false;
      state.authError = action.payload;
    },
    setIsAuthorized: ( state, action: PayloadAction<boolean> ) => {
      state.isAuthorized = action.payload;
    },
    setRefreshToken: ( state, action: PayloadAction<string> ) => {
      state.refreshToken = action.payload;
      localStorage.setItem( localStorageRefreshTokenPath, action.payload );
    },
    setValidated: ( state, action: PayloadAction<boolean> ) => {
      state.validated = action.payload;
    }
  }
} );

export const { 
  setAccessToken, 
  setAuthError, 
  setIsAuthorized, 
  setRefreshToken,
  setValidated
} = authSlice.actions;