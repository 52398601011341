import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useEffect, useState } from 'react';
import { ErrorBox } from '../Boxes/ErrorBox';
import { LoadingIndicatorBox } from '../Boxes/LoadingIndicatorBox';
import { Pagination } from '../Pagination';
import { TableList, TableListProps } from './TableList';

export type TableListQueryProps<
  Item extends object,
  CustomFields extends string = never
> = Omit<TableListProps<Item, CustomFields>, 'items'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryHook: UseQuery<QueryDefinition<void, any, any, Item[], any>>;
  onItemsCountChange?: ( count: number ) => void;
};

export const TableListQuery = <Item extends object, CustomFields extends string = never>( {
  fieldsToDisplay,
  headings,
  queryHook,
  fieldTransformations,
  customFields,
  className,
  onItemsCountChange,
  onItemClick,
  editable,
  onEdit,
}: TableListQueryProps<Item, CustomFields> ) => {
  const { data, isLoading, isError, error, refetch } = queryHook();
  const [ paginatedItems, setPaginatedItems ] = useState( data );

  useEffect( () => {
    if ( data?.length !== undefined && onItemsCountChange ) onItemsCountChange( data.length );
  }, [ data ] );

  const onPaginate = ( items: Item[] ) => {
    setPaginatedItems( items );
  };

  if ( isLoading ) return <LoadingIndicatorBox />;
  if ( isError )
    return (
      <ErrorBox
        error={error || 'Some error occurred'}
        possibleAction={{
          text: 'You can try to reload:',
          buttonText: 'Reload',
          onClick: () => {
            refetch();
          },
        }}
      />
    );

  return (
    <>
      <div className='table-list-query'>
        <TableList
          items={paginatedItems || []}
          fieldsToDisplay={fieldsToDisplay}
          headings={headings}
          fieldTransformations={fieldTransformations}
          customFields={customFields}
          className={className}
          onItemClick={onItemClick}
          editable={editable}
          onEdit={onEdit}
        />
        <Pagination<Item>
          onPageChange={onPaginate}
          paginateItems={data || []}
        />
      </div>
    </>
  );
};
