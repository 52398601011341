

export const themeColors = ( lightTheme?: boolean ) => ( {
  primary: '#F36F3B',
  primary75: '#f0855a',
  primary50: '#f39773c4',
  primary25: '#f36f3b2a',
  danger: '#F22E34',
  dangerLight: '#f76267',
  neutral90: lightTheme ? '#3F3F46' : '#eee',
  neutral80: lightTheme ? '#3F3F46' : '#b1b1b1',
  neutral70: lightTheme ? '#52525B' : '#A1A1AA',
  neutral60: lightTheme ? '#71717A' : '#A1A1AA',
  neutral50: lightTheme ? '#A1A1AA' : '#71717A',
  neutral40: lightTheme ? '#D4D4D8' : '#71717A',
  neutral30: lightTheme ? '#E4E4E7' : '#8E8E93',
  neutral20: lightTheme ? '#E4E4E7' : '#8E8E93',
  neutral10: lightTheme ? '#F4F4F5' : '#3F3F46',
  neutral5: lightTheme ? '#FAFAFA' : '#3F3F46',
  neutral0: lightTheme ? '#fff' : '#37373d',
  secondary: '#8250FF',
} );