import { DateTime } from 'luxon';
import { useState } from 'react';
import { useGetHttpLogsQuery } from '../../../services/logsApi';
import { DateSelect } from '../../ui/Controls';
import styles from './LogsScreen.module.scss';
import { LogsList, LogsMenu, LogsReport } from './components';
import { LogsSubScreen } from './types';

export const LogsScreen = () => {
  const [ start, setStart ] = useState<DateTime>( DateTime.now().startOf( 'hour' ) );
  const [ end, setEnd ] = useState<DateTime>( DateTime.now().endOf( 'hour' ) );

  const { data: logs } = useGetHttpLogsQuery( {
    start: start.toJSDate(),
    end: end.toJSDate(),
  } );
  const [ currentScreen, setCurrentScreen ] = useState<LogsSubScreen>( 'List' );

  const screenRender = () => {
    if ( !logs?.length ) return null;
    if ( currentScreen === 'List' ) return <LogsList logs={logs} />;
    if ( currentScreen === 'Report' ) return <LogsReport logs={logs} />;
    return null;
  };

  console.log( { start, end } );

  return (
    <>
      <div className={styles['filters']}>
        <div className={styles['filter']}>
          <span>Start</span>
          <DateSelect
            value={start}
            onChange={setStart as never}
            maxDate={end}
            minDate={DateTime.now().startOf( 'year' )}
          />
        </div>
        <div className={styles['filter']}>
          <span>End</span>
          <DateSelect
            value={end}
            onChange={setEnd as never}
            minDate={start}
            maxDate={DateTime.now().endOf( 'year' )}
          />
        </div>
      </div>
      {!!logs?.length && (
        <>
          <LogsMenu
            currentScreen={currentScreen}
            onChange={setCurrentScreen}
            screens={[ 'List', 'Report' ]}
          />
          {screenRender()}
        </>
      )}
    </>
  );
};
