import { useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

type Item = { value: number; title: string; color: string };

type MouseEventHandler = ( event: React.MouseEvent, dataIndex: number ) => void;

export interface LogsChartProps {
  data: Item[];
  onClick?: ( item: Item ) => void;
  onHover?: ( item: Item, index: number ) => void;
}

export const LogsChart = ( { data, onClick, onHover }: LogsChartProps ) => {
  const [ hovered, setHovered ] = useState<number>();

  const onItemClick: MouseEventHandler = ( _, index ) => {
    if ( onClick ) {
      const item = data[index];
      onClick( item );
    }
  };

  const onItemOver: MouseEventHandler = ( _, index ) => {
    setHovered( index );
  };

  const onItemOut: MouseEventHandler = () => {
    setHovered( undefined );
  };

  useEffect( () => {
    if ( hovered && onHover ) onHover( data[hovered], hovered );
  }, [ hovered, onHover ] );

  return (
    <PieChart
      data={data}
      lineWidth={50}
      paddingAngle={5}
      label={props => props.dataEntry.value || ''}
      labelPosition={100 - 25}
      labelStyle={{
        fill: '#fff',
        fontSize: 8,
        opacity: '.85',
      }}
      onClick={onItemClick}
      onMouseOver={onItemOver}
      onMouseOut={onItemOut}
    />
  );
};
