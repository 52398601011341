import { DateTime } from 'luxon';
import React, { forwardRef } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateSelectInput } from './DateSelectInput';

export interface DateSelectProps {
  value?: DateTime;
  onChange: ( value: DateTime | undefined ) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
}

export const DateSelect = ( { onChange, value, maxDate, minDate }: DateSelectProps ) => {
  const onPickerChange: ReactDatePickerProps['onChange'] = value => {
    onChange( value ? DateTime.fromJSDate( value ) : undefined );
  };

  return (
    <ReactDatePicker
      onChange={onPickerChange}
      selected={value?.toJSDate()}
      customInput={React.createElement( forwardRef( DateSelectInput ) )}
      maxDate={maxDate?.toJSDate()}
      minDate={minDate?.toJSDate()}
      maxTime={maxDate?.toJSDate()}
      minTime={minDate?.toJSDate()}
      showTimeSelect
    />
  );
};
